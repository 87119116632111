import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import {
  faUtensils,
  faGraduationCap,
  faChild,
faHandHoldingUsd,
faHandHoldingHeart,
faHome,
faHandHoldingWater,
faHeartbeat,
  faStar,
  faStarHalfAlt,
  faPhoneAlt,
  faEnvelope,
  faTimes,
  faArrowLeft,
  faDownload,
  faChevronUp,
  faChevronDown,
  faMapMarker,
  faUserAlt,
faSearchDollar,
faHandsHelping,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faTwitter,
  faLinkedin,
  faPinterest,
  faWhatsapp
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faInstagram,
  faFacebook,
  faTwitter,
  faLinkedin,
  faWhatsapp,
  faPinterest,
  faUtensils,
  faGraduationCap,
  faChild,
  faHandHoldingUsd,
  faHandHoldingHeart,
  faHome,
  faHandHoldingWater,
  faHeartbeat,
  faStar,
  faStarHalfAlt,
  faStarRegular,
  faPhoneAlt,
  faEnvelope,
  faTimes,
  faArrowLeft,
  faDownload,
  faChevronUp,
  faChevronDown,
  faMapMarker,
  faUserAlt,
faSearchDollar,
faHandsHelping,
);
